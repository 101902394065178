<script>
import SinResultados from "@/components/SinResultados.vue";
import Question from "@/components/Modales/Question.vue";

export default {
	components: { SinResultados, Question },
	props: {
		nacimientos: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			question_modal_delete: false,
			question_modal_msg: "¿Estás seguro de eliminar estos datos de nuevos padres?",
			delete_id_nacimiento: ""
		}
	},

	methods: {
		openVistaPrevia(nacimiento) {
			this.$emit('openVistaPrevia', nacimiento);
		},
		openModalForEdit(nacimiento) {
			this.$emit('openModalForEdit', nacimiento);
		},
		acceptDelete() {
			this.$emit('eliminar', this.delete_id_nacimiento);
		},
		openModalDelete(id_nacimiento) {
			this.question_modal_delete = true;
			this.delete_id_nacimiento = id_nacimiento;
		},
		cancelDelete() {
			this.question_modal_delete = false;
			this.delete_id_nacimiento = "";
		},

		nuevaBusqueda() {
			this.$emit("cleanFilter");
		}

	}
}
</script>
<template>
	<div class="row">
		<div class="col-12 mt-3">
			<div class="table-container">
			<table class="table table-custom table-borderless table-custom-responsive mb-0">
				<thead>
				<tr>
					<th scope="col">Nombre colaborador</th>
					<th scope="col" class="text-center">Nombre hijo/a</th>
					<th scope="col" class="text-center">Sexo</th>
					<th scope="col" class="text-center">Peso</th>
					<th scope="col" class="text-center">Estatura</th>
					<th scope="col" class="text-center">Fecha nacimiento</th>
					<th scope="col" class="text-center"> Acciones</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(item) in nacimientos" :key="item.id_nacimiento">
					<td class="th th-nombre"> {{ item.usuario.primer_nombre }} {{ item.usuario.apellido_paterno }} </td>
					<td class="th th-nombre-hijo text-center"> {{ item.nombre_bebe }} </td>
					<td class="th th-sexo text-center"> {{ item.sexo.nombre_sexo }} </td>
					<td class="th th-peso text-center"> {{ item.peso }} kg </td>
					<td class="th th-estatura text-center"> {{ item.medida }} cm </td>
					<td class="th th-fecha-nacimiento text-center"> {{ dateFormatymd(item.fecha_nacimiento) }} </td>
					<td class="th th-accion text-center">
						<div class="dropdown dropdown-custom d-flex justify-content-center">
							<button 
							class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
							type="button" 
							data-bs-toggle="dropdown">
							<i class="fa-solid fa-ellipsis-vertical"></i>
							</button>
							<ul class="dropdown-menu">
								<li>
									<a 
										@click="openVistaPrevia(item)"
										data-bs-toggle="modal" 
										data-bs-target="#modal-detail-birth"
										class="dropdown-item" 
										href="javascript:" target="_blank">
										<i class="fa-regular fa-eye pe-2 color-main"></i>
										Vista previa
									</a>
								</li>
								<li>
									<a 
										@click="openModalForEdit(item)"
										data-bs-toggle="modal" 
										data-bs-target="#modal-form-birth"
										class="dropdown-item" 
										href="javascript:">
										<i class="fa-solid fa-pen pe-2 color-main"></i>
										Editar
									</a>
								</li>
							<li>
							<a 
								@click="openModalDelete(item.id_nacimiento)"
								class="dropdown-item" 
								href="javascript:">
								<i class="fa-regular fa-trash-can pe-2 color-main"></i>
								Eliminar
							</a>
							</li>
						</ul>
						</div>
					</td>
				</tr>
				</tbody>
			</table>

			<SinResultados 
				msg="Busca a otro padre" 
				custom-click 
				@click="nuevaBusqueda"  
				v-if="nacimientos.length === 0"/>
			<Question v-if="question_modal_delete"
				:msg="question_modal_msg"
				:hideCancel="false"
				@cancel="cancelDelete"
				@accept="acceptDelete"
			/>

			</div>

		</div>
	</div>

</template>