<script>
	import moment from "moment";

	export default {
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		methods: {
			formatearFecha (date) {
				let momentTable = moment(date).format('DD/MM/YYYY');
				return momentTable;
			}
		}
	}
</script>
<template>
	<div 
		id="modal-detail-birth"
		class="modal" 
		tabindex="-1"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-secondary fw-bold">
						Nacimiento
					</h5>
					<button 
						ref="closeModal"
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">

					<template v-if="data.img_nacimiento">
						<div class="row mb-3">
							<div class="col-12 text-center">
								<img 
									:src="data?.img_nacimiento"
									class="rounded-circle"
									width="200"
								>
							</div>
						</div>
					</template>
					<template v-if="!data.img_nacimiento">
						<div class="alert alert-secondary text-center mb-3">
							<i class="fa-regular fa-image"></i>
							Sin imagen
						</div>
					</template>
					<div class="row">
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Nombre hij@</span><br/>
								{{ data?.nombre_bebe }}
							</p>
						</div>
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Fecha de nacimiento</span><br/>
								{{ formatearFecha(data?.fecha_nacimiento) }}
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Sexo</span><br/>
								{{ data.sexo?.nombre_sexo }}
							</p>
						</div>
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Peso</span><br/>
								{{ data?.peso }} kg
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Nombre colaborador</span><br/>
								{{ data.usuario?.primer_nombre }} {{ data.usuario?.apellido_paterno }}
							</p>
						</div>
						<div class="col-12 col-lg-6">
							<p class="text-secondary">
								<span class="text-secondary text-opacity-75">Estatura</span><br/>
								{{ data?.medida }} cm
							</p>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button 
						type="button" 
						ref="closeModal"
						class="btn btn-custom-color-white rounded-pill mw-100" 
						data-bs-dismiss="modal">
						<i class="fa-solid fa-angle-left pe-1"></i>
						Volver
					</button>
				</div>
			</div>
		</div>
	</div>
</template>