<script>
//Components
import birthsTable from '@/components/births/birthsTable.vue'
import birthFormModal from '@/components/births/birthFormModal.vue'
import birthDetailModal from '@/components/births/birthDetailModal.vue'
import Loading from "@/components/Loading.vue";
import { mapActions, mapState } from "vuex";

export default {
	components: {
		birthsTable,
		birthFormModal,
		birthDetailModal,
		Loading
	},
	data() {
		return {
			selectedNacimiento: {},
			loading: true,
			loadingModal: false,
			filter: { 
				search: ''
			},
			pagina_actual: 1,
			id_empresa: this.$ls.get("user").empresa[0].id_empresa,
			id_usuario: this.$ls.get("user").id_usuario,
		}
	},

	computed: {
		...mapState("birthsModule", ["nacimientos"]),
		
		filterTable: function () {
			let low = new RegExp(this.filter.search, "i");
			let matcher = this.nacimientos.filter(function (item) {
				return low.test(item.usuario.primer_nombre) || low.test(item.usuario.apellido_paterno) || low.test(item.nombre_bebe) || low.test(item.sexo.nombre_sexo);
			});
			return matcher;
		}
	},

	async created() {
		await this.getUsuarios();
		await this.getGeneros();
		await this.getNacimientos();
	},

	methods: {
		...mapActions("birthsModule", [
			"getBirths", 
			"deleteNacimiento", 
			"addNacimiento", 
			"editNacimiento",
			"getUsuariosList",
			"getGenerosOptions"
		]),
		cleanFilter() {
			this.filter.search = '';
        this.$refs.searchInput.focus();
        this.getNacimientos();
		},
		//GET
		async getUsuarios() {
			const data_ = {
				id_empresa: this.id_empresa,
				id_usuario: this.id_usuario
			}
			const res = await this.getUsuariosList(data_);
			if (!res) {
				this.toastGenericError();
			}
		},

		async getGeneros() {
			const res = await this.getGenerosOptions();
			if (!res) {
				this.toastGenericError();
			}
		},

		async getNacimientos() {
			this.loading = true;
			let data_ = {
				id_empresa: this.id_empresa,
				fecha_inicio: "",
				fecha_fin: "",
				nombre: this.filter.search,
				limite: "50"
			};
			let pagina = this.pagina_actual;
			await this.getBirths({data_, pagina});
			this.loading = false;
		},

		setSelectedNacimiento(nacimiento) {
			this.selectedNacimiento = nacimiento;
		},

		async eliminarNacimiento(id_nacimiento) {
			this.loading = true;

			let data_ = {
				id_eliminador: this.id_usuario, 
				id_empresa: this.id_empresa, 
				id_nacimiento: id_nacimiento
			}
			const resDelete = await this.deleteNacimiento(data_);
			if(resDelete) {
				this.toastBirthDeleteSuccess();
			} else {
				this.toastBirthDeleteError();
			}
			this.loading = false;
		},

		//Limpia el formulario del modal.
		cleanBirth() {
			this.selectedNacimiento = {
				id_nacimiento: null,
				nombre_bebe: '',
				img_nacimiento: '',
				sexo: '',
				peso: '',
				medida: '',
				fecha_nacimiento: null,
				usuario: '',
			};
			this.$refs.birthsFormModal.date = null;
			this.$refs.birthsFormModal.showCroppie = false;
		},

		async saveBirth(form) {
			this.loadingModal = true;

			const payload = {
				nombre_bebe: form.nombre_bebe,
				peso: String(form.peso),
				fecha_nacimiento: form.fecha_nacimiento,
				medida: String(form.medida),
				color: form.color ?? "",
				img_nacimiento: form.img_nacimiento_base64 ?? "",
				formato_nacimiento: form.img_nacimiento.includes("https://") ? "image/jpeg" : form.formato_nacimiento ?? "",
				id_usuario: form.usuario.id_usuario,
				id_sexo: form.sexo.id_sexo,
				id_empresa: this.id_empresa,
			};

			if (form.id_nacimiento) {
				payload.id_nacimiento = form.id_nacimiento;
				payload.id_modificador = this.id_usuario;
			} else {
				payload.id_creador = this.id_usuario;
			}

			const res = await this.birthFormSaveFunction(payload);
			if (!res) {
				this.toastGenericError();
				return;
			}

			this.toastBirthFormSaveSuccess();
			this.loadingModal = false;
			this.$refs.birthsFormModal.closeModal();
			this.cleanBirth();
		},

		async birthFormSaveFunction(payload) {
			if (payload.id_nacimiento) {
				return await this.editNacimiento(payload);
			} else {
				return await this.addNacimiento(payload);
			}
		},

		toastBirthFormSaveSuccess() {
			this.$toast.open({
				message: "Operación exitosa.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
		},
		toastBirthDeleteSuccess() {
			this.$toast.open({
				message: "El nacimiento ha sido eliminado correctamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
		},
		toastBirthDeleteError() {
			this.$toast.open({
				message: "No se ha podido eliminar el nacimiento. Por favor inténtalo más tarde",
				type: "error",
				duration: 0,
				position: "top-right",
			});
		},
	}

}
</script>

<template>
	<section id="births" class="content-container">
		<div class="row align-items-center my-3">
			<div class="col-12 col-lg-9">
				<h3 class="section-head-title ps-0 mb-3 mb-lg-0">
					¡Da a conocer como crece familia de tus colaboradores! Podrás ingresar el nombre, sexo, peso y estatura del recién nacido.
				</h3>
			</div>
			<div class="col-12 col-lg-3 d-flex justify-content-end">
				<button
					@click="cleanBirth()"
					type="button"
					data-bs-toggle="modal" 
					data-bs-target="#modal-form-birth"
					class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
					<i class="fa-solid fa-plus pe-2"></i>
					Crear nacimiento
				</button>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3 mb-lg-0 input-search">
				<input 
					type="search" 
					placeholder="Buscar usuario"
					v-model="filter.search"
					class="form-control input-custom"
					ref="searchInput"
				/>
					<div class="input-search-icon">
						<i class="fa-solid fa-magnifying-glass"></i>
				</div>
			</div>
		</div>

		<Loading 
			v-if="loading"
			pixeles="150px"
		/>

		<birthsTable 
			v-else
			:nacimientos="filterTable"
			@openVistaPrevia="setSelectedNacimiento"
			@openModalForEdit="setSelectedNacimiento"
			@eliminar="eliminarNacimiento"
			@cleanFilter="cleanFilter"
		/>

		<birthDetailModal :data="selectedNacimiento"/>

		<birthFormModal 
			ref="birthsFormModal"
			:loading="loadingModal"
			:loadedBirthData="selectedNacimiento"
			@save="saveBirth"
		/>

	</section>
</template>