<template>
	<div 
		id="modal-form-birth"
		class="modal" 
		tabindex="-1"
	>
		<div class="modal-dialog modal-lg">
			<form 
				class="modal-content"
				@submit.prevent="save"
			>
				<div class="modal-header">
					<h5 class="modal-title text-secondary fw-bold">
						Nacimiento
					</h5>
					<button 
						ref="closeModal"
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 mb-3">
							<label class="text-secondary">Buscar usuario</label>
							<v-select
								class="selvue-custom"
								:class="{ 'selvue-custom-invalid': !validation.usuario && errorForm }"
								:clearable="false"
								:options="usuarios" 
								label="primer_nombre"
								v-model="form.usuario"
								placeholder="Selecciona un usuario">
								<template #selected-option="{ primer_nombre, apellido_paterno }">
									{{ primer_nombre }} {{ apellido_paterno }}
								</template>
								<template #option="{ primer_nombre, apellido_paterno, imagen_perfil, cargo, sucursal }">
									<div class="d-flex my-1">
										<div class="flex-shrink-0">
											<img width="60" :src="handleUserImage(imagen_perfil)" class="rounded-circle">
										</div>
										<div class="flex-grow-1 ms-3">
											<h6 class="font-700 mb-0">{{ primer_nombre }} {{ apellido_paterno }}</h6>
											<small>
												Cargo: {{ cargo[0].nombre_cargo }} <br/>
												Lugar de trabajo: {{ sucursal[0].nombre_sucursal }}
											</small>
										</div>
									</div>
								</template>
							</v-select> 
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-lg-6 mb-3">
							<label class="text-secondary">Nombre hij@</label>
							<input 
								type="text" 
								v-model.trim="form.nombre_bebe"
								class="form-control input-custom" 
								:class="{ 'is-invalid': !validation.nombre_bebe && errorForm }"
								maxlength="50"
								placeholder="Ingresa un nombre"
							/>
						</div>
						<div class="col-12 col-lg-6 mb-3">
							<label class="text-secondary">Sexo</label>
							<v-select
								class="selvue-custom"
								:class="{ 'selvue-custom-invalid': !validation.sexo && errorForm }"
								:clearable="false"
								:options="generosOptions" 
								label="nombre_sexo"
								v-model="form.sexo"
								placeholder="Selecciona un sexo">
							</v-select> 
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-lg-6 mb-3">
							<label class="text-secondary">Peso</label>
							<div class="input-group">
								<input 
									step="any"
									type="number" 
									v-model.number="form.peso"
									class="form-control input-custom" 
									:class="{ 'is-invalid': !validation.peso && errorForm }"
									placeholder="Ingresa un número"
								>
								<span class="input-group-text text-secondary">kg</span>
							</div>
						</div>
						<div class="col-12 col-lg-6 mb-3">
							<label class="text-secondary">Estatura</label>
							<div class="input-group">
								<input 
									step="any"
									type="number" 
									v-model.number="form.medida"
									class="form-control input-custom" 
									:class="{ 'is-invalid': !validation.medida && errorForm }"
									placeholder="Ingresa un número"
								>
								<span class="input-group-text text-secondary">cm</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-lg-6 mb-3">
							<label class="text-secondary">Fecha de nacimiento</label>
							<InputDate 
								:initialDate="date" 
								:requiredField="true"
								:formError="errorForm"
								placeHolder="--/--/--"
								@dateEmit="captureDate"
							/>
						</div>

					</div>
					<div class="row">
						<div class="col-12">
							<label class="text-secondary">Foto (opcional)</label>
							<input 
								type="file"
								id="file"
								class="d-none"
								accept="image/png, image/jpeg"
								@change="croppie"
							>
							<template v-if="!showCroppie">
									<a
										v-if="!form.img_nacimiento" key="sin-img"
										href="javascript:"
										@click="openInputFile()"
										class="capture-img">
										<i class="h1 fa-regular capture-img-icon fa-image"></i>
										Elige un archivo
									</a>
									<a
										v-if="form.img_nacimiento" key="con-img"
										href="javascript:"
										@click="openInputFile()"
										class="capture-img">
										<img :src="form.img_nacimiento" width="200">
									</a>
							</template>
							<template v-if="showCroppie">
								<div class="row">
									<div class="col-12">
										<vue-croppie 
											ref="croppieRef" 
											:enableOrientation="true" 
											:boundary="{ height: 300 }" 
											:viewport="{ width:200, height:200, 'type':'circle' }"
											:enableResize="false"
										>
										</vue-croppie>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button 
						type="button" 
						ref="closeModal"
						class="btn btn-custom-color-white rounded-pill mw-100" 
						data-bs-dismiss="modal">
						<i class="fa-solid fa-angle-left pe-1"></i>
						Volver
					</button>
					<button 
						type="submit"
						class="btn btn-custom-color-blue rounded-pill mw-100"
						
					>
						<span v-if="loading">
							<span width="20" class="spinner-border spinner-border-sm text-light opacity-50"></span>
							Guardando...
						</span>
						<span v-if="!loading">
							Guardar
						</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>


<script>
	import moment from "moment";
	import InputDate from "@/components/forms/InputDate.vue"
	import { mapState } from "vuex";

	export default {
		props: {
			loadedBirthData: {
				type: Object,
				required: false
			},
			loading: {
				type: Boolean,
				required: false
			}
		},
		components: {
			InputDate
		},
		data() {
			return {
				form: this.loadedBirthData,
				errorForm: false,
				showCroppie: false,
				date: null,
        user_default_img: require("@/assets/img/user_default_img.svg"),
			}
		},
		computed: {
			...mapState("birthsModule", ["generosOptions" , "usuarios"]),

			validation() {
				return {
					usuario: !!this.form.usuario,
					nombre_bebe: !!this.form.nombre_bebe,
					sexo: !!this.form.sexo,
					medida: !!this.form.medida,
					peso: !!this.form.peso,
					fecha_nacimiento: !!this.form.fecha_nacimiento,
				}
			},
			isValid() {
				let validation = this.validation
				return Object.keys(validation).every(function (keys){
					return validation[keys]
				})
			},
		},
		watch: {
			loadedBirthData(value) {
				if(value) {
					this.form = this.loadedBirthData;
					//Agrega la fecha al componente inputDate al editar un registro.
					if(value.fecha_nacimiento !== null) {
						this.date = moment(value.fecha_nacimiento).toDate();
					}
				}
				//Oculta el croppie al editar un registro.
				if(value.id_nacimiento !== null) this.showCroppie = false;
			}
		},
		
		methods: {
			handleUserImage(img_perfil) {
        return img_perfil ? img_perfil : this.user_default_img;
      },

			openInputFile() {
				let inputFile = document.getElementById("file");
				if (inputFile) inputFile.click();
			},
			croppie (e) {
				this.showCroppie = true;
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
			
				var reader = new FileReader();
				reader.onload = e => {
					this.$refs.croppieRef.bind({
					url: e.target.result
					});
				};
			
				reader.readAsDataURL(files[0]);
			},
			async crop() {
				try {
					let options = {
						type: 'base64',
						size: { width: 200, height: 200 },
						format: 'jpeg'
					};
					this.$refs.croppieRef.result(options, output => {
						this.form.formato_nacimiento = output.split(/:|;|,/)[1];
						this.form.img_nacimiento_base64 = output.split(/:|;|,/)[3];
					});
				}
				catch(error) {
					console.log(error);
				}
			},
			captureDate(date) {
				this.date = date;
				this.form.fecha_nacimiento = moment(date).format('YYYY-MM-DD');
			},
			closeModal() {
				this.$refs.closeModal.click();
			},
			async save() {
				if(this.isValid) {
					try {
						this.errorForm = false;
						if(this.showCroppie) {
							await this.crop();
						}
						this.$emit('save', this.form);
					} catch(error) {
						console.log(error);
					}
				} else {
					this.errorForm = true;
				}
			}
		}
	}
</script>